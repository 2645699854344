import { useSelector } from 'react-redux'

import { abTestExposeEvent } from 'libs/common/event-tracker/events'
import useTracking from 'hooks/useTracking'
import { getExposee } from 'state/session/selectors'
import { getAbTestByName } from 'state/ab-tests/selectors'
import { getSessionStorageItem, setSessionStorageItem } from 'libs/utils/sessionStorage'
import { getLocalStorageItem, setLocalStorageItem } from 'libs/utils/localStorage'

const getExposedDayKey = (abTestName: string) => `${abTestName}_test_exposed_day`
const getExposedKey = (abTestName: string) => `${abTestName}_test_exposed`

type Props = {
  abTestName: string
  oncePerSessionDay?: boolean
  shouldTrackOnce?: boolean
}

const useAbTestExposeTracking = ({ abTestName, oncePerSessionDay, shouldTrackOnce }: Props) => {
  const { track } = useTracking()
  const exposee = useSelector(getExposee)
  const abTest = useSelector(getAbTestByName(abTestName))

  const trackExpose = () => {
    if (!abTest) return

    if (shouldTrackOnce) {
      const key = getExposedKey(abTestName)

      if (getLocalStorageItem(key) === exposee.anonId) return

      setLocalStorageItem(key, exposee.anonId)
    }

    if (oncePerSessionDay) {
      const key = getExposedDayKey(abTestName)
      const dateToday = new Date().toLocaleDateString()
      const lastExposureDate = getSessionStorageItem(key)

      if (lastExposureDate === dateToday) return

      setSessionStorageItem(key, dateToday)
    }

    track(abTestExposeEvent({ ...exposee, ...abTest }))
  }

  return { trackExpose }
}

export default useAbTestExposeTracking
